.container {
    background-color: var(--blue-15);
    outline: dashed 1rem var(--blue-00);
    color: var(--white-03);
    height: 70vh;
    width: 76vw;
    position: absolute;
    top: 10vh;
    left: 10vw;
    padding: 2vw;

}

.title {
    width: 100%;
    text-align: center;
}

.text {}