.date {
    display: inline-block;
}

.date:first-child {
    text-align: right;
}

.date:last-child {
    text-align: left;
}

.companyName {
    display: inline-block;
    margin: 0;
    font-size: larger;
    font-weight: 500;
}

.companyLogo {
    display: block;
    width: 30%;
}

.headerSection{
    flex-direction: column;
    align-items: flex-end;
}