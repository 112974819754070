:root {
    --menuHeader_height: 2rem;
    --menuHeader_padding_vertical: .25rem;
    --menuHeader_padding_horizontal: 4rem;
    --menuHeader_padding: var(--menuHeader_padding_vertical) var(--menuHeader_padding_horizontal);
}

.collapsableMenu {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.collapsableMenuEntry {
    width: 100%;
    color: var(--blue-09);
}

/* #region ODD/EVEN */

.collapsableMenuEntry:nth-child(odd) {
    background-color: var(--white-00);
    transition: background-color .25s ease-out;
}

.collapsableMenuEntry:nth-child(odd):hover {
    background-color: var(--white-03);
}

.collapsableMenuEntry:nth-child(odd) .collapsableMenuHeader {
    color: var(--blue-15);
}

[id="dark-gradient"] stop:first-child {
    stop-color: var(--blue-12);
}

[id="dark-gradient"] stop:last-child {
    stop-color: var(--blue-15);
}

[id="dark-hover-gradient"] stop:first-child {
    stop-color: var(--blue-09);
}

[id="dark-hover-gradient"] stop:last-child {
    stop-color: var(--blue-12);
}

.collapsableMenuEntry:nth-child(odd) .wave path {
    fill: url('#dark-gradient');
}

.collapsableMenuEntry:nth-child(odd):hover .wave path {
    fill: url('#dark-hover-gradient');
}

.collapsableMenuEntry:nth-child(odd) .highlightedSpan {
    color: var(--white-00);
    background-color: var(--blue-15);
    border-radius: 5px;
    padding: 5px;
}

.collapsableMenuEntry:nth-child(odd) .title {
    border-bottom: dashed 5px var(--blue-15);
}

.collapsableMenuEntry:nth-child(odd) .link {
    color: var(--blue-15);
}


.collapsableMenuEntry:nth-child(even) {
    background-color: var(--blue-15);
    transition: background-color .25s ease-out;
}

.collapsableMenuEntry:nth-child(even):hover {
    background-color: var(--blue-09);
}

.collapsableMenuEntry:nth-child(even) .collapsableMenuHeader {
    color: var(--blue-00);
}

[id="light-gradient"] stop:first-child {
    stop-color: var(--blue-00);
}

[id="light-gradient"] stop:last-child {
    stop-color: var(--blue-03);
}

[id="light-hover-gradient"] stop:first-child {
    stop-color: var(--blue-02);
}

[id="light-hover-gradient"] stop:last-child {
    stop-color: var(--blue-00);
}

.collapsableMenuEntry:nth-child(even) .wave path {
    fill: url('#light-gradient');
}

.collapsableMenuEntry:nth-child(even):hover .wave path {
    fill: url('#light-hover-gradient');
}

.collapsableMenuEntry:nth-child(even) .contentBody {
    color: var(--white-00);
}

.collapsableMenuEntry:nth-child(even) .highlightedSpan {
    color: var(--white-00);
    background-color: var(--blue-15);
    border-radius: 5px;
    padding: 5px;
}

.collapsableMenuEntry:nth-child(even) .title {
    border-bottom: dashed 5px var(--blue-00);
}

.collapsableMenuEntry:nth-child(even) .link {
    color: var(--blue-00);
}

/* #endregion */

.collapsableMenuHeader {
    cursor: pointer;
    min-height: var(--menuHeader_height);
    outline: black 1px solid;
    transition: background-color .25s ease-in-out, color .25s ease-in-out;
}

.collapsableMenuHeader>p {
    padding: var(--menuHeader_padding);
}

.collapsableMenuHeader>div>svg {
    position: relative;
    bottom: calc(-1* var(--menuHeader_padding_vertical));
    transform: translate(0, 1px);
}

.collapsableMenuContent>div>svg {
    position: relative;
    top: var(--menuHeader_padding_vertical);
    transform: translate(0, 1px);
}

.collapsableMenuContent {
    overflow: hidden;
    max-height: 0;
    transition: all .25s ease-out;
}

[class*="active"]>.collapsableMenuContent {
    height: fit-content;
    max-height: 50rem;
}

.titleSection {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: var(--menuHeader_padding);
}

.titleSection>section {
    min-width: 50%;
    display: flex;
}

.titleSection>section:last-child {
    justify-content: flex-end;
}

.titleSection>section:first-child {
    flex-direction: column;
    justify-content: center;
}

.titleSection span {
    padding: calc(2*var(--menuHeader_padding_vertical)) 0;
    font-weight: bold;
}

.title,
.subtitle {
    width: fit-content;
    font-weight: 700;
    margin: 0;
}

.collapsableMenuHeader:hover .title,
.collapsableMenuHeader:hover .subtitle {
    color: var(--light-gray-0);
}

.collapsableMenu .contentBody {
    padding: 0 5%;
    width: 90%;
}

.contentBody .splitContent {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}

.contentBody .splitContent p {
    text-align: center;
}

.link {
    margin: 0 0 2rem;
    display: inline-block;
}